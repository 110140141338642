/*General*/

p {
	margin: 0;
}

a {
	text-decoration: none !important;
	color: var(--theme-color);
}

/*Label*/

.label {
	color: #757575;
	margin-bottom: 14px;
	font-size: 18px;
}

.label.primary {
	color: var(--primary-text-color);
}

/*Input*/

.input {
	display: grid;
	grid-template-columns: fit-content(0) auto fit-content(0);
	grid-template-areas: "icon input buttons";
	gap: 10px;
	align-items: center;
	width: 100%;
	height: 56px;
	background-color: var(--light-gray);
	padding: 10px;
	margin-bottom: 14px;
	border-radius: 15px;
	box-sizing: border-box;
}

.input.compact {
	height: 36px;
	padding: 0px 10px;
}

/*.input.focus {
	border: solid 1px var(--theme-color);
}*/

.input__icon {
	grid-area: icon;
	color: var(--theme-color);
}

.input > input {
	grid-area: input;
	border: none;
	background-color: transparent;
	color: var(--primary-text-color);
	font-size: 18px;
	width: 100%;
	box-sizing: border-box;
	padding: 0;
}

.input > input:focus, .input > input:focus-visible {
	outline: none !important;
	border: none !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: var(--secondary-text-color);
	opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: var(--secondary-text-color);
}

::-ms-input-placeholder { /* Microsoft Edge */
	color: var(--secondary-text-color);
}

.input.error {
	border: solid 1px var(--red);
	margin-bottom: 8px;
}

.input-error {
	color: var(--red);
	font-size: 14px;
	margin-bottom: 6px;
}

.input__buttons {
	grid-area: buttons;
	display: flex;
	gap: 10px;
	color: var(--secondary-text-color);
}

/*Button*/

.button {
	display: grid;
	place-items: center;
	height: 56px;
	border-radius: 15px;
	font-size: 22px;
	margin-bottom: 14px;
}

.button.disabled {
	filter: brightness(80%);
}

.button.ghost {
	height: fit-content;
	font-size: 16px;
	padding: 5px 20px;
	margin-bottom: 0;
}

/*Card*/

.card {
	background-color: white;
	display: flex;
	flex-flow: column;
	padding: 10px;
	border-radius: 15px;
	margin-bottom: 15px;
}

.card__title {
	color: var(--primary-text-color);
	margin-bottom: 5px;
}

.card__subtitle {
	color: var(--secondary-text-color);
	font-size: 14px;
	margin-bottom: 5px;
}

/*Profile icon*/

.profile-icon {
	display: grid;
	place-items: center;
	width: 50px;
	height: 50px;
	border-radius: 100%;
	color: white;
	font-size: 24px;
}

/*Swipe button*/

.swipe-button {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	background-color: white;
	border-radius: 15px;
}

.swipe-button.disabled {
	filter: brightness(85%);	
}

.swipe-button.form-title-mode {
	justify-content: flex-start;
	padding-left: 15px;
	font-size: 22px;
}

.swipe-button.cancel {
	transition: all 0.2s;
}

.swipe-button__slider {
	display: grid;
	place-items: center;
	position: absolute;
	border-radius: 15px;
	color: white;
	font-size: 30px;
	box-sizing: border-box;
}

.swipe-button__title {
	margin-left: 10px;
	font-size: 20px;
}

.swipe-button__icon-wrapper {
	display: flex;
	justify-content: center;
}

.swipe-button__icon-wrapper__icon {
	width: 15px;
}

/*Color chooser*/

.color-chooser {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 10px;
}

/*Icon chooser*/

.icon-chooser {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.icon-chooser__button {
	display: grid;
	place-items: center;
	width: 40px;
	height: 40px;
	font-size: 30px;
	box-sizing: border-box;
}

.icon-chooser__button.selected {
	border: solid 2px var(--theme-color);
	border-radius: 10px;
}

/*React code input*/

.react-code-input {
	width: fit-content;
	margin-bottom: 10px;
}

.react-code-input input:focus, .react-code-input input:focus-visible {
	outline: none !important;
	border: solid 1px var(--theme-color) !important;
}

/*SwipeActions*/

.swipe-actions {
	position: relative;
	box-sizing: border-box;
	overflow-x: hidden;
}

.swipe-actions__content {
	position: absolute;
}

.swipe-actions__button {
	display: grid;
	place-items: center;
	color: white;
	font-size: 24px;
}

.action-sheet__list {
	display: flex;
	flex-flow: column;
	margin-bottom: 10px;
}

.action-sheet__list__title {
	display: grid;
	place-items: center;
	border-radius: 15px 15px 0 0;
	background-color: #efeff0;
	color: var(--secondary-text-color);
	padding: 10px;
	text-align: center;
}

.action-sheet__list .action-sheet__button {
	border-radius: 0;
}

.action-sheet__button {
	display: grid;
	place-items: center;
	font-size: 22px;
	border-radius: 15px;
	padding: 14px 0;
	border-top: solid 1px #d8d7da;
}

.action-sheet__button:first-child {
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}

.action-sheet__button:last-child {
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
}

/*Modal form*/

.modal-form__top {
	display: grid;
	grid-template-columns: 1fr fit-content(0) 1fr;
	grid-template-areas: "blank title cancel";
	align-items: center;
	padding: 10px 15px;
	background-color: white;
}

.modal-form__top__title {
	grid-area: title;
	white-space: nowrap;
	font-size: 20px;
}

.modal-form__top__cancel {
	grid-area: cancel;
	text-align: right;
	color: var(--theme-color);
}