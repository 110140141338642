/*Login*/

#login {
	display: flex;
	flex-flow: column;
	padding: 40px 20px;
	box-sizing: border-box;
	height: 100%;
}

.login-form {
	margin-bottom: 30px;
}

.login-form__title {
	font-size: 40px;
	color: var(--primary-text-color);
	margin-bottom: 10px;
}

.login-form__subtitle {
	color: var(--secondary-text-color);
	margin-bottom: 30px;
}

.login__suggestion_wrapper {
	display: flex;
	justify-content: center;
	gap: 15px;
	color: var(--primary-text-color);
}

.login-form__forgot-password {
	display: flex;
	justify-content: flex-end;
	color: #757575;
}

.login__use-other-account {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	font-weight: 600;
}