/*General*/

:root {
	--theme-color: #4b7bec;
	--theme-color-light: #f0f6fd;
	--primary-text-color: #23272a;
	--secondary-text-color: #9e9e9e;
	--light-gray: #f5f5f5;
	--dark-gray: #878787;
	--blue: #4b7bec;
	--red: #fc5c65;
	--dark-red: #eb3b5a;
	--dark-yellow: #f7b731;
	--purple: #a55eea;
}

html, body, #root {
	height: 100%;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin: 0;
	font-family: Source Sans Pro;
}

body {
	position: fixed;
	user-select: none;
}

/*Form*/

.form {
	display: grid;
	grid-template-rows: 48px auto fit-content(0);
	height: 100%;
	max-height: 100%;
	background-color: var(--light-gray);
	padding: 10px;
	padding-bottom: 20px;
	box-sizing: border-box;
}

.form__content {
	max-width: 100%;
	padding-top: 20px;
	margin-bottom: 10px;
	box-sizing: border-box;
	overflow-y: scroll;
}

/*Loading page*/

.loading-page {
	display: grid;
	place-items: center;
	height: 100%;
}

/*Section*/

.section {
	display: grid;
	grid-template-rows: fit-content(0) auto fit-content(0);
	grid-template-areas:
		"top"
		"center"
		"bottom";
	background-color: var(--light-gray);
	height: 100%;
	padding: 10px;
	padding-bottom: 20px;
	box-sizing: border-box;
}

.topbar {
	grid-area: top;
	display: flex;
	flex-flow: column;
	align-items: center;
	border-radius: 15px;
	background-color: white;
	padding: 10px;
	gap: 10px;
	transition: gap 0.2s ease-in-out;
}

.topbar.search-mode {
	gap: 0;
}

.topbar__top {
	display: grid;
	grid-template-columns: fit-content(0) auto fit-content(0);
	grid-template-areas: "back title buttons";
	align-items: center;
	height: 31px;
	width: 100%;
	transition: height 0.2s ease-in-out;
}

.topbar__top.search-mode {
	height: 0;
	overflow: hidden;
}

.topbar__bottom {
	display: flex;
	flex-flow: row;
	align-items: center;
	width: 100%;
}

.topbar__bottom.search-mode {
	gap: 10px;
}

.topbar__bottom > .input {
	margin-bottom: 0;
}

.topbar__bottom__cancel {
	transition: all 0.2s ease-in-out;
	width: 0;
	overflow: hidden;
	color: var(--theme-color);
	font-size: 18px;
}

.topbar__bottom__cancel.search-mode {
	width: 90px;
}

.topbar__title {
	grid-area: title;
	font-size: 24px;
}

.topbar__buttons {
	grid-area: buttons;
	display: flex;
	gap: 15px;
	white-space: nowrap;
}

.topbar__buttons__button {
	color: var(--theme-color);
	font-size: 24px;
}

/*Navbar*/

.section__navbar {
	grid-area: "bottom";
	display: flex;
	align-items: center;
	background-color: white;
	justify-content: space-between;
	height: 55px;
	border-radius: 15px;
	padding: 5px 20px;
}

.section__navbar__item {
	display: grid;
	place-items: center;
	color: var(--secondary-text-color);
	font-size: 20px;
}

.section__navbar__item__icon {
	font-size: 25px;
}

.section__navbar__item__title {
	white-space: nowrap;
}

.section__navbar__item.selected {
	display: flex;
	width: fit-content;
	gap: 10px;
	color: var(--theme-color);
	position: relative;
	background-color: var(--theme-color-light);
	border-radius: 15px;
	padding: 10px;
}

/*Dot*/
/*.section__navbar__item.selected::before {
	transform: translateX(-50%);
	border-radius: 100%;
	position: absolute;
	background: var(--theme-color);
	bottom: -10px;
	height: 5px;
	content: '';
	width: 5px;
	left: 50%;
}*/

/*Bar*/
/*.section__navbar__item.selected::before {
	transform: translateX(-50%);
	border-radius: 3px;
	position: absolute;
	background: var(--theme-color);
	bottom: -10px;
	height: 3px;
	content: '';
	width: 100%;
	left: 50%;
}*/

.section__content {
	grid-area: center;
	margin: 10px 0;
	width: 100%;
	height: calc(100% - 20px);
	box-sizing: border-box;
	overflow-y: auto;
}

/*My Account page*/

#account {
	display: flex;
	flex-flow: column;
}

.account__profile-card__info-horizontal-wrapper {
	display: flex;
	margin-bottom: 10px;
}

.account__profile-card__info-wrapper__info-vertical-wrapper {
	display: flex;
	flex-flow: column;
	margin-left: 10px;
}

.account__device-list {
	display: flex;
	flex-flow: column;
}

.account__device-list__item {
	background-color: var(--light-gray);
	border-bottom: solid 1px #d8d7da;
	box-sizing: border-box;
	overflow-y: hidden;
	border-radius: 0;
}

.account__device-list__item:first-child {
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}

.account__device-list__item:last-child {
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	border: none;
}

.account__device-list__item__content {
	width: 100%;
	display: grid;
	grid-template-columns: 25px auto fit-content(0) fit-content(0);
	align-items: center;
	gap: 14px;
	box-sizing: border-box;
}

.account__device-list__item__content__icon {
	display: grid;
	place-items: center;
	font-size: 24px;
}

#settings {
	display: grid;
	min-width: 100%;
	grid-template-rows: auto fit-content(0) fit-content(0);
}

.settings__list {
	display: flex;
	flex-flow: column;
}

.settings__list__item {
	display: grid;
	grid-template-columns: fit-content(0) auto fit-content(0);
	align-items: center;
	gap: 10px;
	padding: 10px 15px;
	border-radius: 20px;
	background-color: white;
	margin-bottom: 10px;
}

.settings__list__item__icon {
	font-size: 20px;
	color: var(--theme-color);
}

.settings__list__item__title {
	color: var(--primary-text-color);
	font-size: 18px;
}

/*DeviceID*/

#deviceid {
	display: grid;
	grid-template-rows: auto fit-content(0);
	min-height: 100%;
	padding-top: 20px;
}

.deviceid__code {
	display: flex;
	gap: 10px;
	margin-bottom: 20px;
}

.deviceid__code__digit {
	display: grid;
	place-items: center;
	width: 35px;
	height: 35px;
	border: solid 1px black;
	border-radius: 8px;
	color: var(--primary-text-color);
	font-size: 22px;
	padding-bottom: 3px;
	box-sizing: border-box;
	background-color: white;
}

#passwords__master-key-request {
	display: grid;
	grid-template-rows: fit-content(0) auto fit-content(0);
	grid-template-areas:
		"card"
		"center"
		"bottom";
}

.passwords__list {
	display: flex;
	flex-flow: column;
	margin-bottom: 10px;
}

.passwords__list__folder {
	background-color: white;
}

.passwords__list__folder:first-child {
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}

.passwords__list__folder:last-child {
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
}

.passwords__list__folder:last-child .passwords__list__folder__content__horizontal-wrapper {
	border: none;
}

.passwords__list__folder__content {
	display: grid;
	grid-template-columns: fit-content(0) auto;
	align-items: center;
	gap: 10px;
	font-size: 16px;
	color: var(--dark-gray);
	width: 100%;
}

.passwords__list__folder__content__horizontal-wrapper {
	display: grid;
	align-items: center;
	gap: 10px;
	grid-template-columns: auto fit-content(0) 15px;
	border-bottom: solid 1px var(--light-gray);
	padding-right: 15px;
	height: 100%;
	box-sizing: border-box;
}

.passwords__list__folder__content__horizontal-wrapper.select-mode {
	grid-template-columns: auto fit-content(0);
}

.passwords__list__folder__icon {
	color: var(--theme-color);
	font-size: 20px;
}

.passwords__list__folder__icon.disabled {
	color: var(--dark-gray);
}

.passwords__list__folder__title {
	color: var(--primary-text-color);
	font-size: 22px;
}

.passwords__list__folder__title.disabled {
	color: var(--dark-gray);
}

.passwords__list__folder__size {
	font-size: 24px;
}

.passwords__list__password {
	background-color: white;
}

.passwords__list__password:first-child {
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}

.passwords__list__password:last-child {
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
}

.passwords__list__password__content {
	display: flex;
	flex-flow: column;
	justify-content: center;
	font-size: 18px;
	height: 100%;
	color: var(--primary-text-color);
	border-bottom: solid 1px var(--light-gray);
	box-sizing: border-box;
	width: 100%;
}

.passwords__list__password__horizontal-wrapper {
	display: grid;
	grid-template-columns: fit-content(0) fit-content(0) auto fit-content(0);
	grid-template-areas: "title tags space date";
	align-items: center;
	gap: 10px;
	padding-right: 10px;
}

.passwords__list__password__horizontal-wrapper__title {
	grid-area: title;
	font-size: 18px;
	white-space: nowrap;
}

.passwords__list__password__horizontal-wrapper__tags {
	grid-area: tags;
}

.passwords__list__password__horizontal-wrapper__date {
	grid-area: date;
	color: var(--secondary-text-color);
}

.move-object-modal__object {
	display: flex;
	background-color: white;
	padding: 10px 20px;
	align-items: center;
	gap: 20px;
}

.move-object-modal__object__icon {
	color: var(--theme-color);
	font-size: 30px;
}

.move-object-modal__object__title {
	font-size: 18px;
}

.move-object-modal__object__subtitle {
	color: var(--secondary-text-color);
}

.empty-folder-wrapper {
	display: flex;
	flex-flow: column;
	place-content: center;
	height: 100%;
}